<template>
  <div>
    <a-modal
      width="1220px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑通用加油包"
      @ok="handleSubmit"
    >
      <a-spin :spinning="spinning">
        <a-form
          class="simiot-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-row>
            <!--基本信息， 周期容量设置-->
            <a-col :span="12">
              <span class="item-title">基本信息</span>
              <a-form-item label="通用加油包名称">
                <a-input
                  v-decorator="['name', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { required: true, message: '请输入通用加油包名称' },
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="加油包类型"
                class="simiot-display-item"
              >
                {{ data.product_type }}
              </a-form-item>

              <span class="item-title">周期与容量</span>

              <a-form-item
                label="加油包周期"
                class="simiot-display-item"
              >
                {{ data.service_cycle }}
              </a-form-item>

              <a-form-item
                label="加油包容量"
                class="simiot-display-item"
                v-if="data.product_type_slug === 'addon_flow'"
              >
                {{ data.package_capacity }}
              </a-form-item>

              <a-form-item
                label="加油包语音"
                class="simiot-display-item"
                v-if="data.product_type_slug === 'addon_voice'"
              >
                {{ data.voice_capacity }}
              </a-form-item>

              <div>
                <span class="item-title">零售设置</span>

                <a-form-item label="允许零售">
                  <a-checkbox
                    :checked="isAllowRetail"
                    v-decorator="['is_allow_retail']"
                    @change="changeIsAllowRetail"
                  /> 允许移动端订购
                </a-form-item>

                <a-form-item label="零售价设置者" v-show="isAllowRetail">
                  <a-radio-group
                    v-decorator="['retail_operator', { initialValue: data.retail_operator }]"
                    @change="changeRetailOperator"
                  >
                    <a-radio value="platform">
                      平台
                    </a-radio>
                    <a-radio value="user">
                      零售商
                    </a-radio>
                  </a-radio-group>
                  <font style="color:red;">修改将导致价格变动，谨慎!</font>
                </a-form-item>
              </div>

              <div>
                <span class="item-title">其它设置</span>

                <a-form-item label="允许自订">
                  <a-checkbox
                    :checked="isAllowSelfSubscribe"
                    v-decorator="['is_allow_self_subscribe']"
                    @change="changeIsAllowSelfSubscribe"
                  /> 允许CMP订购
                </a-form-item>
              </div>
            </a-col>

            <!--价格设置-->
            <a-col :span="12">
              <span class="item-title">价格设置</span>

              <a-row style="margin-top: 10px; margin-bottom: 10px" :gutter="24">
                <a-col :span="favorablePriceSpanSize">
                  周期数*
                </a-col>
                <a-col :span="favorablePriceSpanSize">
                  标准价(元)*
                </a-col>
                <a-col :span="favorablePriceSpanSize">
                  底价(元)*
                </a-col>
                <a-col :span="favorablePriceSpanSize" v-if="isAllowRetail && isPlatformRetailOperator">
                  零售价(元)
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                v-for="(k, index) in form.getFieldValue('keys')"
                :key="k"
              >
                <a-col :span="favorablePriceSpanSize">
                  <a-form-item>
                    <a-input-number
                      :min="1"
                      :max="10000000"
                      style="width: 120px"
                      disabled
                      @change="validateFavorablePriceCycles"
                      v-decorator="[`base_product_favorable_prices[${k}].cycles`, {
                        initialValue: base_product_favorable_prices[k] && base_product_favorable_prices[k].cycles,
                        rules: [
                          { required: true, message: '请输入周期数' },
                          { type: 'integer', message: '请输入 1 - 10000000 之间的整数' },
                          { validator: (rule, value, callback) => checkCycles(rule, value, callback, k, index) }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      v-decorator="[`base_product_favorable_prices[${k}].distributor_price`, {
                        initialValue: base_product_favorable_prices[k] && base_product_favorable_prices[k].distributor_price,
                        rules: [
                          { required: true, message: '请输入标准价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize">
                  <a-input-group compact>
                    <a-form-item>
                      <a-space>
                        <a-input-number
                          :min="0"
                          :max="10000000"
                          :step="0.01"
                          :precision="2"
                          style="width: 120px"
                          v-decorator="[`base_product_favorable_prices[${k}].floor_price`, {
                            initialValue: base_product_favorable_prices[k] && base_product_favorable_prices[k].floor_price,
                            rules: [
                              { required: true, message: '请输入底价' },
                              { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                            ]
                          }]"
                        />
                        <a-icon
                          :style="{ display: 'inline-block'}"
                          class="dynamic-delete-button"
                          type="minus-circle-o"
                          v-show="form.getFieldValue('keys').length > 1 && !isPlatformRetailOperator"
                          :disabled="form.getFieldValue('keys').length === 1"
                          @click="removeSetPriceItem(k)"
                        />
                      </a-space>
                    </a-form-item>
                  </a-input-group>
                </a-col>

                <a-col :span="favorablePriceSpanSize" v-if="isAllowRetail && isPlatformRetailOperator">
                  <a-input-group compact>
                    <a-form-item>
                      <a-space>
                        <a-input-number
                          :min="0"
                          :max="10000000"
                          :step="0.01"
                          :precision="2"
                          style="width: 120px"
                          v-decorator="[`base_product_favorable_prices[${k}].retail_price`, {
                            initialValue: base_product_favorable_prices[k] && base_product_favorable_prices[k].retail_price,
                            rules: [
                              { type: 'number', message: '请输入 0 - 10000000 之间的数字' },
                              { validator: (rule, value, callback) => checkRetailPrice(rule, value, callback, k) }
                            ]
                          }]"
                        />
                        <a-icon
                          :style="{ display: 'inline-block'}"
                          class="dynamic-delete-button"
                          type="minus-circle-o"
                          v-show="form.getFieldValue('keys').length > 1"
                          :disabled="form.getFieldValue('keys').length === 1"
                          @click="removeSetPriceItem(k)"
                        />
                      </a-space>
                    </a-form-item>
                  </a-input-group>
                </a-col>
              </a-row>

            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findBaseProductForm, updateBaseProduct } from '@/api/base_product'

export default {
  name: 'EditBaseProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'base_addon' }),
      submitting: false,
      spinning: false,
      capacityUnitOptions: [
        { name: 'MB', value: 'mb' },
        { name: 'GB', value: 'gb' }
      ],
      data: {},
      base_product_favorable_prices: [],
      priceIndex: 1,
      favorablePriceSpanSize: 8,
      isAllowRetail: false, // 是否允许零售
      isPlatformRetailOperator: false, // 零售价设置者是否是平台
      isAllowSelfSubscribe: false // 是否允许自订
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.form.getFieldDecorator('keys', { initialValue: [0], preserve: true })
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      this.spinning = true
      findBaseProductForm(this.id).then((res) => {
        this.data = res.data
        this.isAllowRetail = this.data.is_allow_retail
        this.isPlatformRetailOperator = this.data.retail_operator === 'platform'
        this.favorablePriceSpanSize = (this.isAllowRetail && this.isPlatformRetailOperator) ? 6 : 8
        this.base_product_favorable_prices = res.data.base_product_favorable_prices
        this.priceIndex = this.base_product_favorable_prices.length
        this.isAllowSelfSubscribe = this.data.is_allow_self_subscribe
        const keys = []
        for (let i = 0; i < this.base_product_favorable_prices.length; i++) {
          keys.push(i)
        }
        this.form.setFieldsValue({
          keys: keys
        })

        this.form.setFieldsValue({
          name: this.data.name,
          is_allow_retail: this.data.is_allow_retail,
          retail_operator: this.data.retail_operator,
          remark: this.data.remark
        })

        if (this.data.capacity_unit === 'count') {
          this.excessRateQuantityUnit = '次'
        }

        if (this.data.is_allow_retail || this.userBusinessType === 'custom_day') {
          this.isShowExcessRateItem = false
        }
        this.spinning = false
      })
    },

    // 改变是否允许零售
    changeIsAllowRetail(e) {
      if (e.target.checked) {
        this.isAllowRetail = true
      } else {
        this.isAllowRetail = false
      }

      this.favorablePriceSpanSize = (this.isAllowRetail && this.isPlatformRetailOperator) ? 6 : 8
    },

    // 改变是否允许自订
    changeIsAllowSelfSubscribe(e) {
      if (e.target.checked) {
        this.isAllowSelfSubscribe = true
      } else {
        this.isAllowSelfSubscribe = false
      }
    },

    changeRetailOperator(e) {
      if (e.target.value === 'platform') {
        this.isPlatformRetailOperator = true
      } else {
        this.isPlatformRetailOperator = false
      }

      this.favorablePriceSpanSize = (this.isAllowRetail && this.isPlatformRetailOperator) ? 6 : 8
    },

    checkRetailPrice(rule, value, callback, k) {
      const distributorPrice = this.form.getFieldValue(`base_product_favorable_prices[${k}].distributor_price`)
      if (value < distributorPrice) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('不能低于标准价!')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    checkCycles(rule, value, callback, k, index) {
      if (!value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback()
        return
      }

      const preCycleIndex = this.form.getFieldValue('keys')[index - 1]
      const preCycles = this.form.getFieldValue(`base_product_favorable_prices[${preCycleIndex}].cycles`)
      if (preCycles && preCycles >= value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('应比上个周期数大!')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    // 校验优惠价周期
    validateFavorablePriceCycles() {
      this.form.getFieldValue('keys').forEach((k) => {
        if (this.form.getFieldValue(`base_product_favorable_prices[${k}].cycles`)) {
          this.$nextTick(() => {
            this.form.validateFields([`base_product_favorable_prices[${k}].cycles`], { force: true })
          })
        }
      })
    },

    removeSetPriceItem(k) {
      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')
      // We need at least one passenger
      if (keys.length === 1) {
        return
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys: keys.filter(key => key !== k)
      })

      this.validateFavorablePriceCycles()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitting = true
      this.form.validateFields((err, values) => {
        if (!err) {
          const data = {
            ...values,
            base_product_favorable_prices: values.base_product_favorable_prices.filter(key => key)
          }

          updateBaseProduct(this.id, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        } else {
          this.submitting = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.item-title {
  color: @primary-color;
}
</style>
